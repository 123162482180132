import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import { coinbaseWallet, trustWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Web3ReactProvider } from '@web3-react/core';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { WagmiProvider, createConfig } from 'wagmi';
import App from './App';
import { AuthProvider } from './contexts/auth';
import reportWebVitals from './reportWebVitals';
import { persistor, store } from './store';
import { appConnectors } from './web3/connectors';
import { customMetaMaskWallet } from './web3/connectors/wagmiConnectors/metamask';
import { APP_NETWORKS_SUPPORT } from './web3/constants/networks';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();

const rpcUrl = process.env.REACT_APP_RPC_URL as string;
const connectors = connectorsForWallets(
  [
    {
      groupName: 'Popular',
      wallets: [customMetaMaskWallet, walletConnectWallet, coinbaseWallet, trustWallet],
    },
  ],
  {
    appName: 'Axor',
    projectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID!,
  },
);

const chain = {
  id: Number(process.env.REACT_APP_CHAIN_ID),
  name: APP_NETWORKS_SUPPORT[Number(process.env.REACT_APP_CHAIN_ID)].details.chainName, // Provide the appropriate chain name
  nativeCurrency: {
    name: APP_NETWORKS_SUPPORT[Number(process.env.REACT_APP_CHAIN_ID)].details.nativeCurrency.name,
    symbol: APP_NETWORKS_SUPPORT[Number(process.env.REACT_APP_CHAIN_ID)].details.nativeCurrency.symbol,
    decimals: APP_NETWORKS_SUPPORT[Number(process.env.REACT_APP_CHAIN_ID)].details.nativeCurrency.decimals,
  },
  rpcUrls: {
    default: {
      http: [rpcUrl],
    },
  },
};
// @ts-ignore:next-line
const config = createConfig({
  chains: [chain],
  connectors,
});

root.render(
  <Web3ReactProvider connectors={appConnectors}>
    <WagmiProvider reconnectOnMount={true} config={config}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={<></>} persistor={persistor}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </WagmiProvider>
  </Web3ReactProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
