import moment from 'moment';
import { DateRangeOption, FilterType } from 'src/types/profit-and-lost';

export const DEFAULT_MODAL_WIDTH = 528; // unit pixcel
export const METHOD_IS_ORDERBOOK = 2;

//Profit and loss

export const DATE_RANGE: DateRangeOption[] = [
  {
    id: FilterType.Last_7_DAYS,
    label: 'Last 7 days',
    value: moment().subtract(7, 'days').unix(),
  },
  {
    id: FilterType.Last_30_DAYS,
    label: 'Last 30 days',
    value: moment().subtract(30, 'days').unix(),
  },
  {
    id: FilterType.CUSTOM_RANGE,
    label: 'Custom range',
    value: moment().subtract(30, 'days').unix(),
  },
];

export const WALLET_ANALYTICS_QUERY_PARAMS = {
  OVERVIEW: 'overview',
  BALANCES: 'balances',
  PROFIT_AND_LOSS: 'profit-and-loss',
};

export type BooleanBE = 0 | 1;

export const NOTIFICATION_TYPE = {
  //notification DEX
  UpdateTradingLevel: 'UpdateTradingLevel',
  PairCreated: 'PairCreated',
  PairDeleted: 'PairDeleted',
  UserTierChanged: 'UserTierChanged',
  // notification IAO
  WHITELISTS: 'Whitelists',
  IAO_PARTICIPATION_START: 'IaoParticipationStart',
  IAO_VAULT_SUCCEEDED: 'IaoVaultSuccess',
  IAO_VAULT_FAILED: 'IaoVaultFailed',
  IAO_NON_VAULT_SUCCEEDED: 'IaoNonVaultSuccess',
  REJECT_IAO_REVENUE: 'RejectIaoRevenue',
  REDEMPTION_REQUEST_APPROVAL: 'RedemptionRequestApproval',
  REDEMPTION_REQUEST_REJECT: 'RedemptionRequestReject',
  NEW_AFFILIATE_OFFER: 'NewAffiliateOffer',
  ACCEPT_AFFILIATE_OFFER: 'AcceptAffiliateOffer',
  SECOND_APPROVED_IAO_REQUEST: 'SecondApprovedIAORequest',
  CREATE_IAO_EVENT_PUBLIC: 'CreateIAOEventPublic',
  REJECT_IAO_REQUEST: 'RejectIAORequest',
  APPROVE_IAO_REVENUE: 'ApproveIAORevenue',
  ANNOUNCEMENT: 'Announcement',
  CANCEL_WITHDRAWAL: 'CancelWithdrawal',
  WITHDRAW_REQUEST_SUCCEEDED: 'WithdrawRequestSucceeded',
  WITHDRAW_REQUEST_UNSUCCEEDED: 'WithdrawRequestUnsucceeded',
};

export enum MARGIN_MODE {
  CROSS = 'CROSS',
}

export enum TimeFilterPortfolio {
  '24h' = '1',
  '7D' = '7',
  '28D' = '28',
  '30D' = '30',
  'All' = '365',
}

export enum ERankType {
  ABSOLUTE_PNL = 'ABSOLUTE_PNL',
  RELATIVE_PNL = 'RELATIVE_PNL',
}

export enum EPnlRankingTimeStep {
  ALL = 'ALL',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  EPOCH = 'EPOCH',
}

export enum RestricedUserCode {
  INACTIVE = 'USER_00033',
  LOCATION = 'RESTRICTION_00006',
  IP = 'RESTRICTION_00005',
}

export enum MaintenanceCode {
  MAINTENANCE = 'SITE_MAINTENANCE_00001'
}

export const blockedUserCode = [
  RestricedUserCode.INACTIVE,
  RestricedUserCode.LOCATION,
  RestricedUserCode.IP,
];

export const maintenanceUserCode = [
  MaintenanceCode.MAINTENANCE,
];
