import loadable, { DefaultComponent } from '@loadable/component';
import React from 'react';
import { Redirect, Route, RouteProps, Switch } from 'react-router';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'src/constants/paths';
import { ExchangePageLoading } from 'src/pages/loadings';
import Layout from '../components/01.layout/layoutDefault';
import Portfolio from '../pages/portfolio';
import { LoadingPage } from './components/LoadingPage';
import { PrivateRoute } from './components/PrivateRoute';

const LoadingByTemplate: React.FC = () => {
  const history = useHistory();

  if (history.location.pathname.includes(PATHS.exchange())) {
    return <ExchangePageLoading />;
  }
  return <LoadingPage />;
};

function loadableWFallback(loadFn: (props: {}) => Promise<DefaultComponent<{}>>) {
  return loadable(loadFn, {
    fallback: <LoadingByTemplate />,
  });
}

const NotFound = loadableWFallback(() => import('./components/NotFound'));
const Restricted = loadableWFallback(() => import('src/pages/restricted'));
const BadGateway = loadableWFallback(() => import('src/pages/bad-gateway'));
const Maintenance = loadableWFallback(() => import('src/pages/maintenance'));
const Faqs = loadableWFallback(() => import('src/pages/faqs'));
const Terms = loadableWFallback(() => import('src/pages/terms'));
const Privacy = loadableWFallback(() => import('src/pages/privacy'));

const Exchange = loadableWFallback(() => import('src/pages/exchange'));
const Overview = loadableWFallback(() => import('src/pages/portfolio/components/overview'));
const OpenOrder = loadableWFallback(
  () => import('src/pages/portfolio/components/transaction-history/open-order'),
);
const openHistory = loadableWFallback(
  () => import('src/pages/portfolio/components/transaction-history/order-history'),
);
const TradeHistorys = loadableWFallback(
  () => import('src/pages/portfolio/components/transaction-history/trade-history'),
);
const TransactionHistory = loadableWFallback(
  () => import('src/pages/portfolio/components/transaction-history/transaction-history'),
);
const PNL = loadableWFallback(() => import('src/pages/portfolio/components/prl'));
const RankingAbsolute = loadableWFallback(
  () => import('src/pages/portfolio/components/ranking/absolute'),
);
const RankingRelative = loadableWFallback(
  () => import('src/pages/portfolio/components/ranking/relative'),
);
const RankingDaily = loadableWFallback(
  () => import('src/pages/portfolio/components/ranking/daily'),
);
const RankingEpoch = loadableWFallback(
  () => import('src/pages/portfolio/components/ranking/epoch'),
);
const RankingBadges = loadableWFallback(
  () => import('src/pages/portfolio/components/ranking/badges'),
);

const LayoutDashboard: React.FC<RouteProps> = (props) => {
  return (
    <Layout>
      <Route {...props} />
    </Layout>
  );
};

const LayoutPortfolio: React.FC<RouteProps> = (props) => {
  return (
    <Portfolio>
      <PrivateRoute {...props} />
    </Portfolio>
  );
};

const Routes: React.FC = () => {
  return (
    <Switch>
      <LayoutDashboard exact path="/" render={() => <Redirect to={PATHS.exchange()} />} />
      <LayoutPortfolio exact path={PATHS.overview()} component={Overview} />
      <LayoutPortfolio exact path={PATHS.pnl()} component={PNL} />
      <LayoutPortfolio exact path={PATHS.rankingAbsolute()} component={RankingAbsolute} />
      <LayoutPortfolio exact path={PATHS.rankingRelative()} component={RankingRelative} />
      <LayoutPortfolio exact path={PATHS.rankingDaily()} component={RankingDaily} />
      <LayoutPortfolio exact path={PATHS.rankingEpoch()} component={RankingEpoch} />
      <LayoutPortfolio exact path={PATHS.rankingBadges()} component={RankingBadges} />
      <LayoutPortfolio exact path={PATHS.openOrder()} component={OpenOrder} />
      <LayoutPortfolio exact path={PATHS.transactionHistory()} component={TransactionHistory} />
      <LayoutPortfolio exact path={PATHS.orderHistory()} component={openHistory} />
      <LayoutPortfolio exact path={PATHS.tradeHistory()} component={TradeHistorys} />
      <LayoutDashboard exact path={`${PATHS.exchange()}/:pairNameParam?`} component={Exchange} />
      <LayoutDashboard exact path={`${PATHS.restricted()}`} component={Restricted} />
      <LayoutDashboard exact path={`${PATHS.badGateway()}`} component={BadGateway} />
      <LayoutDashboard exact path={`${PATHS.maintenance()}`} component={Maintenance} />
      <LayoutDashboard exact path={`${PATHS.faqs()}`} component={Faqs} />
      <LayoutDashboard exact path={`${PATHS.terms()}`} component={Terms} />
      <LayoutDashboard exact path={`${PATHS.privacy()}`} component={Privacy} />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default Routes;
