import { Form, ModalProps } from 'antd';
import React, { useEffect, useState } from 'react';
import AxorModal from '..';

import { ExceptionOutlined } from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ButtonContained from 'src/components/02.buttons/ButtonContained';
import CheckBoxFrac from 'src/components/10.check-box';
import { THEME_MODE } from 'src/interfaces/theme';
import { PreferenceService } from 'src/services/preference';
import { RootState } from 'src/store';
import './styles.scss';
import { useLocation } from 'react-router';
interface IDeactivatedModal extends ModalProps {}

const UserAgreementModal: React.FC<IDeactivatedModal> = (props) => {
  const { t } = useTranslation('translations');
  const theme = useSelector((state: any) => state.theme);
  const accessToken = useSelector((state: RootState) => state.user.accessToken);
  const [form] = useForm();
  const values = Form.useWatch([], form);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const location = useLocation();
  const [userData, setUserData] = useState<any>(null);

  useEffect(() => {
    form
      .validateFields()
      .then((formValues) => {
        setSubmitDisabled(!Object.values(formValues).length); // check keys to handle init state
      })
      .catch(() => {
        setSubmitDisabled(true);
      });
  }, [values]);

  const handleGetMe = async () => {
    try {
      const preferenceService = new PreferenceService();
      const res = await preferenceService.getMe();
      if (res) {
        setUserData(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitForm = async () => {
    try {
      const preferenceService = new PreferenceService();
      await preferenceService.acceptAgreement();
      const res = await preferenceService.getMe();
      setUserData(res.data);
      form.resetFields();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetMe();
  }, [accessToken]);

  return (
    <AxorModal
      open={userData?.isAcceptAgreement === false && location?.pathname !== '/terms'}
      {...props}
      title={
        <div
          className={theme === THEME_MODE.DARK ? 'modal-title--dark' : 'modal-title--light'}
          style={{ textAlign: 'center' }}
        >
          {t('commons.userAgreement')}
        </div>
      }
    >
      <Form
        onFinish={handleSubmitForm}
        initialValues={{
          term1: false,
          term2: false,
          term3: false,
        }}
        form={form}
        className={`user-agreement ${
          theme === THEME_MODE.DARK ? 'user-agreement--dark' : 'user-agreement--light'
        }`}
      >
        <ExceptionOutlined style={{}} />
        <div className="user-agreement__content">
          <Form.Item
            name={'term1'}
            rules={[
              {
                validator: async (_: any, value: number) => {
                  if (!value) {
                    return Promise.reject('');
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <CheckBoxFrac>
              <span className="term-info">{t('commons.term1')}</span>
            </CheckBoxFrac>
          </Form.Item>
          <Form.Item
            name={'term2'}
            rules={[
              {
                validator: async (_: any, value: number) => {
                  if (!value) {
                    return Promise.reject('');
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <CheckBoxFrac>
              <span className="term-info">{t('commons.term2')}</span>
            </CheckBoxFrac>
          </Form.Item>
          <Form.Item
            name={'term3'}
            rules={[
              {
                validator: async (_: any, value: number) => {
                  if (!value) {
                    return Promise.reject('');
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <CheckBoxFrac>
              <span className="term-info">{t('commons.term3')}</span>{' '}
              <a className="term-of-use" href="/terms" target="__blank">
                {t('commons.termOfUse')}
              </a>
              <span className="term-info">.</span>
            </CheckBoxFrac>
          </Form.Item>
        </div>
        <ButtonContained disabled={submitDisabled} htmlType="submit">
          {t('commons.continue')}
        </ButtonContained>
      </Form>
    </AxorModal>
  );
};

export default UserAgreementModal;
