export const BASE_SOCKET = process.env.REACT_APP_BASE_SOCKET;
export const API_ENDPOINT = process.env.REACT_APP_API_URL;
export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
export const NETWORK_NAME = process.env.REACT_APP_NETWORK_NAME;
export const BLOCK_EXPLORER_URL = process.env.REACT_APP_BLOCK_EXPLORER_URL;
export const BSC_RPC_URL = process.env.REACT_APP_RPC_URL;
export const TOKEN_PERPETUAL = process.env.REACT_APP_TOKEN_PERPETUAL;
export const BASE_DECIMALS_CONTRACT = process.env.REACT_APP_BASE_DECIMALS;
export const TOKEN_DECIMALS = process.env.REACT_APP_TOKEN_DECIMALS;
export const LIMIT_ORDER_FEATURE_CONTRACT_ADDRESS =
  process.env.REACT_APP_LIMIT_ORDER_FEATURE_CONTRACT_ADDRESS;
export const CONTRACT_CREATOR_PROXY = process.env.REACT_APP_CONTRACT_CREATOR_PROXY;
export const LIMIT_FEE_MARKER = process.env.REACT_APP_LIMIT_FEE_PERCENT_MARKER;
export const EPOCH_DAY = process.env.REACT_APP_EPOCH_DAY;
export const POSITION_HOLD_RATE = process.env.REACT_APP_POSITION_HOLD_RATE;
export const TOKEN_NATIVE_SYMBOL = process.env.REACT_APP_TOKEN_NATIVE_SYMBOL;
