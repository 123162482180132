import { Layout } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppHeader from 'src/components/01.layout/header/AppHeader';
import { getStorageUserLanguage, getStorageUserTheme, setStorageTheme } from 'src/helpers/storage';
import useWindowSize from 'src/hooks/useWindowSize';
import i18n from 'src/i18n/i18n';
import { THEME_MODE } from 'src/interfaces/theme';
import { setTheme } from 'src/store/actions/theme';
import { LANGUAGE_CODE } from './header/ProfileDetail/MultiLanguageContent';
import './styles.scss';
import MobileMenuDetail from './header/MobileMenuDetail';
import { useAuth } from 'src/hooks/useAuth';
import { useAccount } from 'wagmi';
import { RootState } from 'src/store';
import useOpenModal from 'src/hooks/useOpenModal';
import { useEagerConnect, useWalletListener } from 'src/web3/hooks';
import WindowActiveListener from 'src/hooks/useWindowActiveListener';

const { Content } = Layout;

export const formatDate = 'DD-MM-YYYY HH:mm:ss';

interface ILayoutProps {
  children?: React.ReactNode;
}

export enum ThemesMode {
  dark = 'dark',
  light = 'light',
}

function generateLanguage(language: string) {
  switch (language) {
    case 'en':
      return LANGUAGE_CODE.ENGLISH;
    case 'cn':
      return LANGUAGE_CODE.CHINESE;
    case 'zh':
      return LANGUAGE_CODE.CHINESE;
    case 'ja':
      return LANGUAGE_CODE.JAPANESE;
    case 'jp':
      return LANGUAGE_CODE.JAPANESE;
    case 'vi':
      return LANGUAGE_CODE.VIETNAMESE;
    default:
      break;
  }
}

const LayoutComponent: React.FC<ILayoutProps> = ({ children }) => {
  const userTheme = useSelector((state: RootState) => state.theme);

  useEagerConnect();
  useWalletListener();
  useOpenModal();
  WindowActiveListener();

  const dispatch = useDispatch();
  const { address: account } = useAccount();

  const { isDesktop } = useWindowSize();
  const { isAuth, wrongChain, isAuthChecking } = useAuth();
  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false);

  const userLoginSuccess = useMemo(() => {
    return isAuth && !!account && !wrongChain && !isAuthChecking;
  }, [isAuth, account, wrongChain, isAuthChecking]);

  const handleOpenMenuMobile = (bool: boolean) => {
    setIsOpenMenuMobile(bool);
  };

  useEffect(() => {
    isDesktop && setIsOpenMenuMobile(false);
  }, [isDesktop]);

  const persisData = () => {
    const themeAttribute = document.documentElement.getAttribute('data-theme');
    if (!themeAttribute && userTheme) {
      setStorageTheme(userTheme.toLocaleLowerCase());
    }
    if (
      getStorageUserTheme() &&
      (getStorageUserTheme()?.toLocaleLowerCase() !== userTheme.toLocaleLowerCase() ||
        getStorageUserTheme()?.toLocaleLowerCase() !== themeAttribute)
    ) {
      if (getStorageUserTheme() === THEME_MODE.DARK?.toLocaleLowerCase()) {
        dispatch(setTheme(THEME_MODE.DARK));
        document.documentElement.setAttribute('data-theme', THEME_MODE.DARK.toLocaleLowerCase());
      } else {
        dispatch(setTheme(THEME_MODE.LIGHT));
        document.documentElement.setAttribute('data-theme', THEME_MODE.LIGHT.toLocaleLowerCase());
      }
    }

    if (getStorageUserLanguage() && getStorageUserLanguage() !== 'en') {
      i18n.changeLanguage(generateLanguage(getStorageUserLanguage() as string));
    } else {
      i18n.changeLanguage(LANGUAGE_CODE.ENGLISH);
    }
  };

  useEffect(() => {
    persisData();
    window.addEventListener('storage', persisData);
    return () => {
      window.removeEventListener('storage', persisData);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="layout">
      <Layout className="container">
        <AppHeader isOpenMenuMobile={isOpenMenuMobile} setIsOpenMenuMobile={handleOpenMenuMobile} />
        <Layout className={`site-layout ${userTheme}`}>
          <Content className="site-layout-background">
            <div className={!isDesktop && isOpenMenuMobile ? '' : 'display-none'}>
              <MobileMenuDetail
                address={userLoginSuccess ? (account as string) : ''}
                onClickItem={() => setIsOpenMenuMobile(false)}
                isOpen={!isDesktop && isOpenMenuMobile}
              />
            </div>
            <div>{children}</div>
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default LayoutComponent;
