import { Layout } from 'antd';
import { ReactComponent as AppLogo } from 'src/assets/icons/header/axor/axor-logo.svg';
import { useEffect, useState } from 'react';
import {
  FacebookIcon,
  GooglePlusIcon,
  InstagramIcon,
  LinkedInIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  WeChatIcon,
  WhatSappIcon,
  YoutubeIcon,
} from 'src/assets/icons/IconComponent';
import { convertHttpsUrl } from 'src/helpers';
import useWindowSize from 'src/hooks/useWindowSize';
import { SiteSettingService } from 'src/services/site-setting-service';
import './styles.scss';

export interface socialSetting {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: any;
  name: string;
  icon: string;
  type: string;
  link: string;
  status: string;
}

export interface settingGeneral {
  email: string;
  isMaintenance: boolean;
}

export enum SOCIAL_TYPE {
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  TELEGRAM = 'TELEGRAM',
  LINKEDIN = 'LINKEDIN',
  YOUTUBE = 'YOUTUBE',
  WHATSAPP = 'WHATSAPP',
  REDDIT = 'REDDIT',
  INSTAGRAM = 'INSTAGRAM',
  GOOGLE_PLUS = 'GOOGLE_PLUS',
  WECHAT = 'WECHAT',
}

const icons = {
  [SOCIAL_TYPE.FACEBOOK]: <FacebookIcon />,
  [SOCIAL_TYPE.GOOGLE_PLUS]: <GooglePlusIcon />,
  [SOCIAL_TYPE.INSTAGRAM]: <InstagramIcon />,
  [SOCIAL_TYPE.LINKEDIN]: <LinkedInIcon />,
  [SOCIAL_TYPE.REDDIT]: <RedditIcon />,
  [SOCIAL_TYPE.TELEGRAM]: <TelegramIcon />,
  [SOCIAL_TYPE.TWITTER]: <TwitterIcon />,
  [SOCIAL_TYPE.WECHAT]: <WeChatIcon />,
  [SOCIAL_TYPE.WHATSAPP]: <WhatSappIcon />,
  [SOCIAL_TYPE.YOUTUBE]: <YoutubeIcon />,
};

const { Footer } = Layout;
const logoMobileWidth = 90;
const logoDesktopWidth = 107;

const FooterPortfolio = () => {
  const { isMobile } = useWindowSize();
  const [socialData, setSocialData] = useState<socialSetting[]>([]);
  const [settingGeneral, setSettingGeneral] = useState<settingGeneral>({} as settingGeneral);

  const getSocialData = async () => {
    try {
      const siteSettingService = new SiteSettingService();
      const { data } = await siteSettingService.getSocialSettings();
      const { data: dataGeneral } = await siteSettingService.getSettingsGeneral();
      const newData = data.filter((social: any) => social.status !== 'DISABLE');
      setSocialData(newData);
      setSettingGeneral(dataGeneral);
    } catch {}
  };

  useEffect(() => {
    getSocialData();
  }, []);

  const contactArray = [
    {
      title: 'About US',
      order: isMobile ? 1 : 1,
      items: [
        { title: 'About US', link: '#' },
        { title: 'Announcements', link: '#' },
      ],
    },
    {
      title: 'Products',
      order: isMobile ? 2 : 2,
      items: [
        // { title: 'Spot Trade', link: '#' },
        // { title: 'Convert', link: '#' },
        { title: 'Futures USDⓈ-M', link: '#' },
        // { title: 'Earn', link: '#' },
      ],
    },
    // {
    //   title: 'Services',
    //   order: isMobile ? 3 : 6,
    //   items: [
    //     { title: 'Reward Center', link: '#' },
    //     { title: 'Referral Program', link: '#' },
    //   ],
    // },
    {
      title: 'Support',
      order: isMobile ? 3 : 3,
      items: [
        { title: 'FAQ', link: '/faqs' },
        { title: 'Support Center', link: '#' },
        { title: 'API Document', link: '#' },
      ],
    },
    {
      title: 'Legal',
      order: isMobile ? 4 : 4,
      items: [
        { title: 'Term of Use', link: '/terms' },
        { title: 'Privacy Policy', link: '/privacy' },
      ],
    },
    {
      title: 'Contact Us',
      order: isMobile ? 5 : 5,
      items: [{ title: settingGeneral?.email || '', link: '#' }],
    },
  ];

  return (
    <Footer className={`footer`}>
      <div className="logo">
        <div className="flex">
          <AppLogo width={!isMobile ? logoDesktopWidth : logoMobileWidth} color={'#fff'} />
          {socialData.length > 0 && (
            <div className="social">
              {socialData?.map((social, index: number) => {
                return (
                  <>
                    <a
                      target="__blank"
                      className="link"
                      href={convertHttpsUrl(social.link)}
                      key={index}
                    >
                      {icons[social.type as SOCIAL_TYPE] ? (
                        icons[social.type as SOCIAL_TYPE]
                      ) : (
                        <></>
                      )}
                    </a>
                  </>
                );
              })}
            </div>
          )}
        </div>
        <div className="logo__items">
          <span>© Axor Ltd.</span>
          <span>Trade Crypto with Axor</span>
        </div>
      </div>
      <div className="contact">
        <div className="contact__item">
          {contactArray.map((contact) => {
            return (
              <div className="contact__group" style={{ order: contact.order }}>
                <div className="contact__group__title">{contact.title}</div>
                <div className="contact__group__items">
                  {contact.items.map((item) => {
                    return (
                      <a className="link" href={item.link}>
                        {item.title}
                      </a>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
        {socialData.length > 0 && (
          <div className="contact__social">
            {socialData.map((social) => {
              return (
                <>
                  <a target="__blank" className="link" href={convertHttpsUrl(social.link)}>
                    {icons[social.type as SOCIAL_TYPE] ? icons[social.type as SOCIAL_TYPE] : <></>}
                  </a>
                </>
              );
            })}
          </div>
        )}
      </div>
    </Footer>
  );
};

export default FooterPortfolio;
