/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-ignore
import i18next from 'i18next';
import io from 'socket.io-client';
import { fracToast2 } from 'src/components/07.toast';
import { OrderSideFuture } from 'src/constants/exchange';
import { onReceiveUpdates } from 'src/helpers/orderBooks';
import { getStorageJwtToken } from 'src/helpers/storage';
import i18n from 'src/i18n/i18n';
import { Instrument } from 'src/interfaces/instrument';
import { ITicker } from 'src/interfaces/ticker';
import { SocketEvent } from 'src/socket/SocketEvent';
import { store } from 'src/store';
import { setMarginModeAccount } from 'src/store/actions/account';
import { updateActiveOrders } from 'src/store/actions/orders';
import { updateActivePosition } from 'src/store/actions/positions';
import { getTicker24hSuccess } from 'src/store/actions/ticker';
import { addNewRecentTrades, changeUpdateAt } from 'src/store/actions/trade';
import { SET_ASSETS } from 'src/store/constants/account';
import { IAccount } from 'src/store/reducers/account';
import { IPositions } from 'src/store/reducers/positions';
import { IOrder } from 'src/types/orders';
import eventBus from './event-bus';
import groupBy from 'lodash/groupBy';

let account = store.getState().currentAccount.asset;
let orderMode = store.getState().currentAccount.orderMode;

store.subscribe(() => {
  account = store.getState().currentAccount.asset;
});

export class BaseSocket {
  private static instance: BaseSocket;
  private socket: any;

  // @ts-ignore
  private currentInstrument?: Instrument;
  public token?: string;

  public static getToken() {
    if (BaseSocket.instance) {
      return BaseSocket.instance?.token;
    }
    return null;
  }

  public static getInstance(): BaseSocket {
    if (!BaseSocket.instance) {
      BaseSocket.instance = new BaseSocket();
    }

    return BaseSocket.instance;
  }

  public connect(accessToken?: string): void {
    this.socket = io(process.env.REACT_APP_BASE_SOCKET!, {
      path: '/socket.io',
      transports: ['websocket'],
      query: {
        authorization: accessToken,
      },
    });
    this.token = accessToken!;
    this.listenOrders();
    this.listenPosition();
    this.listen24Ticker();
    this.listenNotifications();
    this.listenAdjustMode();
    this.socket.on('reconnect', () => {
      if (this.currentInstrument) {
        const instrument = this.currentInstrument;
        this.currentInstrument = undefined;
        this.listenInstrumentEvents(instrument);
      }
    });
  }

  public reconnect(accessToken?: string): void {
    if (this.socket) {
      this.socket.disconnect();
    }
    this.connect(accessToken);
    this.socket.on('connect', () => {
      if (this.currentInstrument) {
        const instrument = this.currentInstrument;
        this.currentInstrument = undefined;
        this.listenInstrumentEvents(instrument);
      }
    });
  }

  listenAdjustMode(): void {
    this.socket.on('adjust-leverage', async (data: any) => {
      const resources = i18next.getResource(i18n.language, 'orders', 'orders');
      if (this.currentInstrument?.symbol === data.symbol) {
        if (data.status === 'SUCCESS') {
          store.dispatch(setMarginModeAccount(data));
          fracToast2.success(resources.leverage_adjusted_successfully);
        } else {
          fracToast2.error(resources.leverage_adjusted_fail);
        }
      }
    });
  }

  listenInstrumentEvents(instrument: Instrument): void {
    if (instrument?.symbol === this.currentInstrument?.symbol) {
      return;
    }
    if (this.currentInstrument?.symbol && this.socket) {
      this.socket.off(`trades_${this.currentInstrument?.symbol}`);
      this.socket.off(`orderbook_${this.currentInstrument?.symbol}`);
      this.socket.emit('unsubscribe-symbol', this.currentInstrument?.symbol);
    }
    this.currentInstrument = instrument;
    if (!this.currentInstrument?.symbol || !this.socket) {
      return;
    }
    this.socket.emit('subscribe-symbol', this.currentInstrument?.symbol);

    // this.socket.off(`trades_${this.currentInstrument.symbol}`);
    // this.socket.on(`trades_${this.currentInstrument.symbol}`, (data: ITrade[]) => {
    //   console.log('trades_', data);
    //   const symbol = store.getState().instrument.currentInstrument.symbol;
    //   if (data.length <= 0 || data[0].symbol !== symbol) {
    //     return;
    //   }
    //   eventBus.dispatch(SocketEvent.TradesCreated, data);
    //   store.dispatch(addNewRecentTrades(data.reverse()));
    //   addNewTrades(store, data);
    // });

    // this.socket.off(`orderbook_${this.currentInstrument.symbol}`);
    this.socket.on(`orderbook_${this.currentInstrument?.symbol}`, (data: string) => {
      const decodedData = Buffer.from(data, 'base64').toString('ascii');
      onReceiveUpdates(store, JSON.parse(decodedData));
    });
  }
  listen24Ticker(): void {
    this.socket.on(`tickers`, (data: ITicker[]) => {
      store.dispatch(getTicker24hSuccess(data || []));
      const symbol = store.getState().instrument.currentInstrument?.symbol;
      const currentTicker = data.find((ticker: ITicker) => ticker.symbol === symbol);
      if (!currentTicker) return;
      const marketTrade = store.getState().trade;

      if (currentTicker?.updateAt) {
        store.dispatch(changeUpdateAt(currentTicker.updateAt));
      }
      if (marketTrade.isReady) {
        if (!currentTicker?.trades || currentTicker.trades.length <= 0) return;
        eventBus.dispatch(SocketEvent.TradesCreated, currentTicker.trades);
        store.dispatch(addNewRecentTrades([...currentTicker.trades].reverse()));
      } else {
        if (!currentTicker?.trades || currentTicker.trades.length <= 0) return;
        store.dispatch(addNewRecentTrades([...currentTicker.trades].reverse()));
      }
    });
  }
  listenPosition(): void {
    this.socket.on('position', async (position: IPositions) => {
      await updateActivePosition(store, position);
    });
  }
  listenOrders(): void {
    this.socket.on('orders', async (orders: IOrder[]) => {
      await updateActiveOrders(store, orders);
      eventBus.dispatch(SocketEvent.TradesUpdated, {});
    });
  }

  listenBalance(): void {
    this.socket.on(SocketEvent.balance, async (data: IAccount) => {
      // eventBus.dispatch(SocketEvent.balance, {});
      const index = account.findIndex(
        (item: any) =>
          Number(item.id) === Number(data.id) &&
          item.asset.toLowerCase() === data.asset.toLowerCase(),
      );
      if (index !== -1) {
        const updatedAccount = { ...account[index], balance: data.balance };
        const updatedAccounts = [...account];
        updatedAccounts[index] = updatedAccount;

        store.dispatch({
          type: SET_ASSETS,
          payload: updatedAccounts,
        });
      }
    });
  }

  disconnectSocket(): void {
    this.socket.offAny();
    this.socket.disconnect();
  }

  listenNotifications(): void {
    const resources = i18next.getResource(i18n.language, 'orders', 'transfer');
    const resourcesOrder = i18next.getResource(i18n.language, 'orders', 'noti');
    this.socket.on(
      'notifications',
      (
        notifications: {
          type: string;
          title: string;
          amount?: string;
          event?: string;
          isHidden?: boolean;
          code?: string;
          side?: string;
          contractType?: string;
        }[],
      ) => {
        const groupNotifications = groupBy(
          notifications,
          (item) => `${item.event}-${item.type}-${item.code}-${item.side}-${item.isHidden}`,
        );
        const events = Object.keys(groupNotifications);
        for (const key of events) {
          const notification = groupNotifications[key][0];
          if (notification?.amount) {
            // if (notification.type === 'success') {
            //   console.log('resources.transfer_success', resources.transfer_success);
            //   fracToast2.success(resources.transfer_success);
            //   // store.dispatch(getBalanceSpot());
            // }
            //  else {
            //   console.log('resources.transfer_fail', resources.transfer_fail);
            //   fracToast2.error(resources.transfer_fail);
            // }
          }
          if (notification.event === 'WithdrawSubmitted') {
            if (notification.type === 'error' || notification?.code === 'E001') {
              fracToast2.error(resourcesOrder.withdraw_failed);
            } else {
              fracToast2.success(resourcesOrder.withdraw_success);
            }
          }
          if (notification.event === 'OrderCanceled') {
            if (notification.isHidden === false) {
              if (notification?.code === 'E001') {
                fracToast2.error(resourcesOrder.insufficient_balance);
              } else {
                fracToast2.success(
                  notification.side === OrderSideFuture.buy
                    ? resourcesOrder.order_buy_cancel
                    : resourcesOrder.order_sell_cancel,
                );
              }
            }
          }
        }
      },
    );
  }
}
